import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { config } from "process";
import { isArray } from "lodash";
interface USstate {
  name: string;
  abbreviation: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  signUpFormData: {

    acc_type: "seller_account" | "buyer_account" | null;

    contact_name: string;

    phone_number: number;

    email: string;

    password: string;

    password_confirmation: string;

    agree_to_policy: boolean;

    name_of_business: string;

    license_number: number;

    county: string;

    state: string;

    zip_code: number;

    address: string;

  };

  steps: number;

  acc_typeError:string;

  contact_nameError: string;

  phone_numberError: string;

  emailError: string;

  passwordError:string,

  passFieldError: string;

  showPassword: boolean;

  policyError: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;

  businessNameError:string;

  licenseNumberError:string;

  countyError:string;

  stateError:string;

  zipCodeError:string;

  addressError:string;

  usStates:USstate[] | null| undefined;



  isStep1Valid :boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  emailValidationApiCallId: string ="";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start

      signUpFormData: {

        acc_type: null,

        contact_name: '',

        email: '',

        phone_number: 0,

        password: '',

        password_confirmation: '',

        agree_to_policy: false,

        name_of_business: '',

        license_number: 0,

        county: '',

        state: '',

        zip_code: 0,

        address: '',

      },

      steps : 0,

      acc_typeError:'',

      contact_nameError : '',

      phone_numberError:'',

      emailError: '',

      passwordError:'',

      passFieldError: '',

      showPassword: false,



      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      policyError :'',

      businessNameError :"",

      licenseNumberError:"",

      countyError:"",

      stateError:'',

      zipCodeError:"",

      addressError:"",



      usStates: configJSON.usStates,



      isStep1Valid:false
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");
    

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.emailValidationApiCallId){

          this.arrayholder = responseJson.data;

          if(!responseJson.exists){

            this.setSteps()

          }

          else{

            this.setState({emailError:'This account already exists. Please sign in.'})

          }

         

          if (this.arrayholder && this.arrayholder.length !== 0) {

            let regexData = this.arrayholder[0];

          if (regexData.password_validation_rules) {

            this.setState({

              emailError: JSON.stringify(regexData.password_validation_rules)

            });

          }

        }

        }

        else if (apiRequestCallId === this.createAccountApiCallId){

          this.arrayholder = responseJson.data;
          this.errorFormApi(responseJson)
      
            localStorage.setItem("accessToken",responseJson.meta.token)
            this.routetopage('EmialAccountRegistrationConfirmation')
     

        

         

          
        }

     
      }
    }


    
    // Customizable Area End
  }

  // Customizable Area Start
  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }



  errorFormApi =(responseJson:{errors?:{attr:string;message:string}[]})=>{
    // @ts-ignore
    if (responseJson.errors) {
      const newState = {
        businessNameError: "",
        licenseNumberError: "",
        countyError: "",
        stateError: "",
        zipCodeError: "",
        addressError: ""
      };
     // @ts-ignore
     if(isArray(responseJson.errors)) 
       // @ts-ignore
      responseJson.errors.forEach((error: { attr: any; message: string; }) => {
        switch (error.attr) {
          case "name_of_business":
              newState.businessNameError = error.message;
              break;
            case "zip_code":
              newState.zipCodeError = error.message;
              break;
            case "license_number":
              newState.licenseNumberError = error.message;
              break;
            case "county":
              newState.countyError = error.message;
              break;
            case "state":
              newState.stateError = error.message;
              break;
            case "address":
              newState.addressError = error.message;
              break;
        }
     
      });
    
      this.setState(newState);
    }
  }

  createAccountApiCall(): any {



    const header = {

      "Content-Type": configJSON.contentTypeApiAddDetail

    };


    const {

      acc_type,

      contact_name,

      email,

      phone_number,

      password,

      password_confirmation,

      agree_to_policy,

      name_of_business,

      license_number,

      county,

      state,

      zip_code,

      address

    } = this.state.signUpFormData;

  

    const attrs = {

      contact_name: contact_name, 

      email: email, 

      phone_number: "+" + this.state.countryCodeSelected + phone_number, 

      password: password, 

      password_confirmation: password_confirmation, 

      agree_to_policy: agree_to_policy, 

      name_of_business: name_of_business, 

      license_number: license_number, 

      county: county, 

      state: state, 

      zip_code: zip_code, 

      address: address 

    };

  

    const data = {

      type: acc_type, 

      attributes: attrs

    };



    const httpBody = {

      data: data,

    };



    const requestMessage = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    

    this.createAccountApiCallId = requestMessage.messageId;

    

    requestMessage.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),

      configJSON.accountsAPiEndPoint

    );



    requestMessage.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(header)

    );

  

    requestMessage.addData(

      getName(MessageEnum.RestAPIRequestBodyMessage),

      JSON.stringify(httpBody)

    );

  

    requestMessage.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      configJSON.apiMethodTypeAddDetail

    );

  

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }


  getValidationsStep1() {

    const headers = {

      "Content-Type":`${configJSON.validationApiContentType}`

    };



    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.emailValidationApiCallId = getValidationsMsg.messageId;



    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `${configJSON.validateEmailApiUrl}?email=${this.state.signUpFormData.email}`

    );



    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      configJSON.validationApiMethodType

    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);



    return true;

  }

  handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const { name, checked } = event.target;
    
      this.setState({signUpFormData: {

        ...this.state.signUpFormData,

        

        [name]: checked ,

      }

    })


  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const { name, value, } = event.target;

    this.setState({

      signUpFormData: {

        ...this.state.signUpFormData,

        

        [name]:  value,

      },

    });

    

  };












  validateForm = () => {

    const { email, password, password_confirmation, agree_to_policy } = this.state.signUpFormData;

    let isValid = true;


    if (!email.includes('@')) {

      this.setState({ emailError: 'Invalid email address' });

      isValid = false;

    } else {

      this.setState({ emailError: '' });

    }



    if (password !== password_confirmation) {

      this.setState({ passFieldError: 'Passwords do not match' });

      isValid = false;

    } else {

      this.setState({ passFieldError: '' });

    }



    if (!agree_to_policy) {

      this.setState({ policyError: 'You must agree to the privacy policy' });

      isValid = false;

    } else {

      this.setState({ policyError: '' });

    }
    if (isValid){
    this.createAccountApiCall()
    }

    return isValid;

  };

  

   setSteps =  ()=>{

    if ( this.state.steps === undefined || this.state.steps <= 0){

    this.setState({steps :2})

    }

    else {

      this.setState({steps :0})

    }

  }



  step1Validation = () => {

    const {

      acc_type,

      contact_name,

      email,

      phone_number,

      password,

      password_confirmation,

    } = this.state.signUpFormData;

    

    let isValid = true;



    let acc_typeError = '';

    let contact_nameError = '';

    let emailError = '';

    let phone_numberError = '';

    let passwordError = '';

    let passFieldError = '';

 

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;

    const phoneRegex = /^\d{10}$/;

  

    if (!acc_type || acc_type.length < 3) {

      acc_typeError = 'Account type must Selected';

      isValid = false;

    }

  

    const NameRegex =/^[A-Za-z\s]+$/

    if (!contact_name || /^\d+$/.test(contact_name)|| !NameRegex.test(contact_name)) {

      contact_nameError = 'Contact name is required';

      isValid = false;

    }

  


    if (!email || !emailRegex.test(email)) {

      emailError = 'Invalid email address';

      isValid = false;

    }

  

    if (!phone_number || !phoneRegex.test(phone_number.toString())) {

      phone_numberError = 'Phone number must be exactly 10 digits';

      isValid = false;

    }

  

    if (!passwordRegex.test(password)) {

      passwordError = configJSON.errorPasswordNotValid
      isValid = false;

    }
    if (password !== password_confirmation) {

      passFieldError = 'Passwords do not match';

      isValid = false;

    }

  

    this.setState({

      acc_typeError,

      contact_nameError,

      emailError,

      phone_numberError,

      passwordError,

      passFieldError,

      showPassword: false
    });

  

    return isValid;

  };





  validateSignup = () => {

    const {

      agree_to_policy,

      name_of_business,

      license_number,

      county,

      state,

      zip_code,

      address,

    } = this.state.signUpFormData;

    

    let isValid = true;



    let policyError = '';

    let businessNameError = '';

    let licenseNumberError = '';

    let countyError = '';

    let stateError = '';

    let zipCodeError = '';

    let addressError = '';



    const zipCodeRegex = /^\d{5}$/;

    const licenseNumberRegex = /^[A-Za-z0-9]{8,}$/;


    if (!agree_to_policy) {

      policyError = 'You must agree to the privacy policy';

      isValid = false;

    }


    if (!name_of_business) {

      businessNameError = 'Business name is required';

      isValid = false;

    }


    if (!license_number || !licenseNumberRegex.test(license_number.toString())) {

      licenseNumberError = 'License number must be at least 6 alphanumeric characters';

      isValid = false;

    }


    if (!county) {

      countyError = 'County is required';

      isValid = false;

    }


    if (!state) {

      stateError = 'State is required';

      isValid = false;

    }


    if (!zip_code || !zipCodeRegex.test(zip_code.toString())) {

      zipCodeError = 'Zip code must be exactly 5 digits';

      isValid = false;

    }

  

    if (!address) {

      addressError = 'Address is required';

      isValid = false;

    }

  

    this.setState({

      policyError,

      businessNameError,

      licenseNumberError,

      countyError,

      stateError,

      zipCodeError,

      addressError,

    });

  
    if(isValid){
      this.createAccountApiCall()
    }
    return isValid;

  };



  goToNextStep = ()=>{

    

    if(this.step1Validation()){

      this.getValidationsStep1()

    }

  };


  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword, // Toggle visibility
    }));
  };




  routetopage= (screenName: string) => {

    if (screenName.length > 0) {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
      runEngine.sendMessage(message.id, message);
    }
  }

  setErrors = (errors:any) => {

    const errorMapping:any = {

     

      policy: 'policyError',

      business_name: 'businessNameError',

      license_number: 'licenseNumberError',

      county: 'countyError',

      state: 'stateError',

      zip_code: 'zipCodeError',

      address: 'addressError'

    };



    let newState = { ...this.state };

    interface errintf  {

      attr:string;

      message: string;

    }

    errors.length && errors.forEach((error:errintf) => {

      if(error.attr){

        const stateKey = errorMapping[error.attr];

        if (stateKey) {



          //@ts-ignore

          newState[stateKey] = error.message;

          

        }

      }
      

     

    });


  }


  // Customizable Area End
}
