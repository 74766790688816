import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token:string|undefined|null;
  subscriptionData : any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Subscriptionbilling2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
   subscriptionApiCallID: string ="";
   createSubscriberApiCallID:string ='';
   useDetailsApiCallID:string =""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
    
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: "",
      subscriptionData : [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    
    
    const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
     var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
     

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.subscriptionApiCallID){
          this.setState({subscriptionData: responseJson.data})
          
          }
          if( apiRequestCallId === this.createSubscriberApiCallID){
          
            if (responseJson && responseJson.checkout_session.url) {
              // Navigate to the returned URL
              window.location.href = responseJson.checkout_session.url;
            } else {
              // Handle error case
              alert('Error in Stripe Service')
              console.error("Error in Stripe integration:", responseJson);
            }
          }
        if(apiRequestCallId  === this.useDetailsApiCallID){
          console.log(responseJson.errors.length, 'len ')
          if(responseJson.errors.length>0){
            this.routetopage('EmailAccountLoginBlock')
          }
          else if(responseJson.data.attributes.subscribed){
            this.routetopage('Settings2')
          }
          this.saveLoggedInUserData(responseJson.data)
          
        }
          
          
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
   componentDidMount():any{
      this.getToken();
      this.getLoggendInUserData()
      this.getSubscriptionDetails() 
    }

  routetopage= (screenName: string) => {

    if (screenName.length > 0) {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
      runEngine.sendMessage(message.id, message);
    }
  }

  getToken =()=>{
    const accessToken:string|null|undefined =localStorage.getItem('accessToken')
    localStorage.getItem('authToken');
    if(localStorage.getItem('authToken') !== null || localStorage.getItem('authToken') !== undefined  ){
      
      this.setState({token:localStorage.getItem('authToken')?.toString()})
    }
    else{
      this.routetopage('EmailAccountLoginBlock');
    }
  }


  getSubscriptionDetails() {

    const headers = {

      "Content-Type":`${configJSON.validationApiContentType}`,
      "token": this.state.token || localStorage.getItem('authToken') 
    }

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.subscriptionApiCallID = getValidationsMsg.messageId;



    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `${configJSON.StripeApiFeatch}`

    );



    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      configJSON.validationApiMethodType

    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);



    return true;

  }

    createSubscriber() {
    const headers = {
      "Content-Type": `${configJSON.validationApiContentType}`,
      "token": this.state.token || localStorage.getItem('authToken')
    };

    // Payload for the POST request
    const payload = {
      "account": {
        "subscription_id":"1",
        "success_url": `${window.location.origin}/InfoPage`
      }
    };

    const createSubscriberMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createSubscriberApiCallID = createSubscriberMsg.messageId;

    createSubscriberMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.CreateCustomer
    );

    createSubscriberMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createSubscriberMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    createSubscriberMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    runEngine.sendMessage(createSubscriberMsg.id, createSubscriberMsg);

    return true;
  }


  getLoggendInUserData(){

    const headers = {

      "Content-Type":`${configJSON.validationApiContentType}`,
      "token": this.state.token || localStorage.getItem('authToken') 
    }

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.useDetailsApiCallID = getValidationsMsg.messageId;



    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `${configJSON.UserInfoApi}`

    );



    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      configJSON.validationApiMethodType

    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);



    return true;

  }
  saveLoggedInUserData(responseJson: any) {
    if (responseJson ) {
     localStorage.setItem('user',JSON.stringify(responseJson))
    }
  }


  
  // Customizable Area End
}
