import React from "react";

// Customizable Area Start
import { Input } from '@builder/component-library';

import { View, StyleSheet } from "react-native";
import { FormLabel ,
  Grid,
  Box,
  Typography,
  Button,
  Paper
} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start


   styleOfUi:any = {
    accountSelectContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    accountButton: {
      padding: '5px 15px',
      borderRadius: '20px',
      border: '1px solid',
      borderColor: 'transparent',
      marginHorizontal: '10px',
    },
    accountSelected: {
      borderColor: 'green',
      backgroundColor: '#E6F8E0',
    },
    accountUnselected: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      cursor:'pointer'
    },
    accountText: {
      fontSize: '16px',
    },
    accountSelectedText: {
      color: 'green',
      fontWeight: 'bold',
    },
    accountUnselectedText: {
      color: 'gray',
    },
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
        <Grid container component="main" sx={{ height: '100vh' }}>
           <Grid item xs={12} sm={7} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 1,
              mx: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'right',
            }}
          >
            <Box sx={{ mt: 1 }}>
              <Grid container sx={{ px: '43px', display: 'flex', flexDirection: 'column' }}>
              <View style={styles.formWrapper}>


              <View style={{...this.styleOfUi.accountSelectContainer, marginBottom:'20px'}}>
                    <Box
                      style={
                      { ...this.styleOfUi.accountButton,cursor:'pointer',
                        ...(this.state.type === 'buyer_account' ? this.styleOfUi.accountSelected : this.styleOfUi.accountUnselected),
                      }
                      }
                      data-test-id= "btnBuyer"
                      onClick={() => this.setState({type:"buyer_account"})}
                    >
                      <Typography
                      component="p" 
                      style={
                        {...this.styleOfUi.accountText,cursor:'pointer',
                        ...(this.state.type === 'buyer_account' ? this.styleOfUi.accountSelected : this.styleOfUi.accountUnselected),
                    }}
                      >
                        Buyer Account
                      </Typography>
                    </Box>

                    <Box
                      style={
                        {...this.styleOfUi.accountButton,
                        ...(this.state.type === 'seller_account' ? this.styleOfUi.accountSelected : this.styleOfUi.accountUnselected),}
                      }
                      onClick={() => this.setState({type:"seller_account"})}
                      data-test-id= "btnSeller"
                    >
                      <Typography
                        style={
                        {...this.styleOfUi.accountText,
                          ...(this.state.type === 'seller_account' ? this.styleOfUi.accountSelected : this.styleOfUi.accountUnselected),
                    }}
                      >
                        Seller Account
                      </Typography>
                    </Box>
                  </View>

                  <>
                  <div style={{ paddingBottom:'20px'}}>
                              <Typography style={{ fontFamily: 'Inter,sans-serif', fontSize: 30, fontWeight: "700", }} >
                                Welcome
                              </Typography>
                              <Typography style={{ fontFamily: 'Inter,sans-serif', fontSize: 14, fontWeight: "700" }}>
                                Sign Up Now
                              </Typography>
                              </div>
                  </>

              <View    style={{marginBottom:'20px'}}>
              <FormLabel>Email</FormLabel>
                <Input
                  data-test-id="txtInputEmail"
                  placeholder={"Email"}
                  value={this.state.email}
                  onChangeText={(text) => this.setEmail(text)}
                
                />
              </View>
              <View style={{marginBottom:'20px'}}>
              <FormLabel>Password</FormLabel>
                <Input
                  data-test-id="txtInputPassword"
                  secureTextEntry={this.state.enablePasswordField}
                  placeholder={"Password"}
                  value={this.state.password}
                  onChangeText={(text) => this.setPassword(text)}
                  rightIcon={
                    <Button
                      data-test-id="btnTogglePassword"
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      style={{color:'#94A3B8',...styles.removeBackground}}
                      
                    >{this.state.enablePasswordField ? (
                      <Visibility/>  
                    ) : (
                      <VisibilityOff/>
                    )}</Button>
                  }
                  
                />
              </View>
              <View style={{ display: "flex", alignItems: "flex-end" , paddingLeft:'20px', marginBottom:'20px'}}>
              
                  <FormLabel  
                  data-test-id={"btnForgotPassword"}
                  onClick={() => this.goToForgotPassword()}
                  sx={{'& .MuiFormLabel-root':{
                    fontSize:'14px',
                    alignSelf:'end'
                  }}}>Forgot Password ?</FormLabel>
                
              </View>
              <View
              >
                <Button
                  data-test-id={"btnEmailLogIn"}
                  onClick={() => this.doEmailLogInApi()}
                  sx={{
                    backgroundColor: '#067B3E',
                    '& .MuiButtonBase-root': {
                      backgroundColor: '#067B3E',
                      color:'#ffff'
                    },
                    '&:hover': {
                      backgroundColor: '#067B3E', // Prevent hover effect by setting it to the same color
                    },}
                  }
                  
                  style={{color:'#ffff'}}
                  
                >Login</Button>
              </View>
              
              <View
                style={styles.buttonsWrapper}
              > 
              </View>
              </View>
              </Grid>
            </Box>
          </Box>
        </Grid>
     
     <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              'url("https://www.nanalyze.com/app/uploads/2020/01/Cannabis-Indoor-Farming-Stock-Teaser-1024x682.jpg")',
            backgroundRepeat: 'no-repeat', 
            backgroundSize: 'cover',    
            backgroundPosition: 'center', 
            display: 'block'
          }}
        />
          
      </Grid>
      
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
