// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Grid,
  Box,
  TextField,
  RadioGroup,
  Radio,
  Typography,
  Button,
  Select,
  InputAdornment,
  IconButton,
  Modal,
  MenuItem
  // Customizable Area End
} from "@mui/material";



// Customizable Area Start
import EmailAccountRegistrationController, { Props } from "./EmailAccountRegistrationController";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText'
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import { Formik } from "formik";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

// Customizable Area End

export default class EmailAccountRegistrationWeb extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  SignupForm = () => {
    const formStyle = {
      inputStyle: {
        marginBottom: 10, width: "100%",
        fontSize: '14px',
      },
      btnstyle: {
        BackgroundColor: '#067B3E',
        width: '100%'
      },
      formlable: {
        fontFamily: 'Inter,sans-serif',
        fontSize: '14px',
        fontWeight: 700,
        marginBottom: '15px',
        lineHeight: '22px',
      }
    }
    return <Formik
      initialValues={this.state.signUpFormData}
      validateOnChange={false}
      onSubmit={() => {
       this.validateSignup()
      }}
      data-testid='formikForm'
    >
      {({ handleSubmit }) => (
        <form autoComplete="off" onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}
          style={{ width: '100%' }}
          data-testid = "formData"
        >
         
          { this.state.steps <= 1 ? <>
            <>
            <div style={{ marginBottom: "20px" }}>
                <Typography style={{ fontFamily: 'Inter,sans-serif', fontSize: 30, fontWeight: "700", }} >
                  Welcome
                </Typography>
                <Typography style={{ fontFamily: 'Inter,sans-serif', fontSize: 14, fontWeight: "700" }}>
                  Sign Up Now
                </Typography>
               </div>
              </> 
          <Box>
            {/* Account Type Radio Buttons */}
            <Box>
              <RadioGroup
                aria-label="account-type"
                name="acc_type"
                value={this.state.signUpFormData.acc_type}
                onChange={this.handleChange}
                row
              >
                <FormControlLabel value="seller_account" control={<Radio />} label="Seller account" style={{ ...formStyle.formlable }} />
                <FormControlLabel value="buyer_account" control={<Radio />} label="Buyer account" style={{ ...formStyle.formlable }} />
              </RadioGroup>
              <label style={{...formStyle.formlable,fontWeight:'normal',color:'red'}}>{this.state.acc_typeError}</label>
                
            </Box>
            
            <Box >
              <label style={formStyle.formlable}>Contact Name</label>
              <TextField
                style={formStyle.inputStyle}
                size="small"
                placeholder="Contact name"
                variant="outlined"
                name="contact_name"
                data-testeid = 'contact_name'
                value={this.state.signUpFormData.contact_name}
                onChange={this.handleChange}
                helperText={this.state.contact_nameError}
                error={!!this.state.contact_nameError}
                data-testid = "contact_name"
              />
            </Box>
            {/* Email Field */}
            <Box>
              <label style={formStyle.formlable}>Email id</label>
              <TextField
                size="small"
                style={formStyle.inputStyle}
                placeholder="Email"
                variant="outlined"
                name="email"
                value={this.state.signUpFormData.email}
                error={!!this.state.emailError}
                helperText={this.state.emailError}
                onChange={this.handleChange}
              />
            </Box>
            {/* Phone Number Field */}
            <Box>
              <label style={formStyle.formlable}>Phone number</label>
              <TextField
                style={formStyle.inputStyle}
                placeholder="Phone number"
                variant="outlined"
                name="phone_number"
                value={this.state.signUpFormData.phone_number || ''}
                onChange={this.handleChange}
                size={'small'}
                helperText={this.state.phone_numberError}
                error={!!this.state.phone_numberError}
              />
            </Box>
            {/* Password Field */}
            <Box>
              <label style={formStyle.formlable}>Password</label>
              <TextField
                style={formStyle.inputStyle}
                placeholder="Password"
                variant="outlined"
                name="password"
                value={this.state.signUpFormData.password}
                onChange={this.handleChange}
                error={!!this.state.passwordError}
                helperText={this.state.passwordError}
                size={'small'}
                type={this.state.showPassword ? "text":"password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        edge="end"
                      >
                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              
            </Box>
            {/* Confirm Password Field */}
            <Box>
              <label style={formStyle.formlable}>Confirm password</label>
              <TextField
                style={formStyle.inputStyle}
                placeholder="Confirm password"
                variant="outlined"
                name="password_confirmation"
                type={this.state.showPassword ? "text":"password"}
                value={this.state.signUpFormData.password_confirmation}
                onChange={this.handleChange}
                error={!!this.state.passFieldError}
                helperText={this.state.passFieldError}
                size={'small'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        edge="end"
                      >
                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box> </>:
            <>
             <div style={{ marginBottom: "20px" }}>
             <Modal
              open={this.state.showPassword}
              onClose={this.handleClickShowPassword}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              
            >
          <Paper elevation={3} sx={{ padding: 4, textAlign: 'center', borderRadius: 2 ,
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    overflow:'scroll',
                                    maxHeight:'70vh',
                                    bgcolor: 'background.paper',
                                    border: '2px solid #000',
                                    boxShadow: 24,
                                  }}>

                                     <IconButton
              aria-label="close"
              onClick={this.handleClickShowPassword}
              style={{ position: 'absolute', top: 10, right: 10 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 ,color:"#067B3E"}}>
            Agree to Terms and Conditions
            </Typography>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <p><strong>Last Updated</strong>: 8/18/24</p>
<p>Welcome to CannaGen. These Terms and Conditions govern your use of our website and web-based app. By accessing our website/app you agree to comply with and be bound by these terms. If you do not agree with these terms, please do not use our website/app.</p>
<p><strong>1. Legal Compliance</strong></p>
<p><strong>1.1 Jurisdiction:</strong> You acknowledge and agree that cannabis sales are subject to various federal, state, and local laws. Legal cannabis sales must take place between two state licensed parties entirely off the site/app. By subscribing to this website/app, you affirm that you are a licensed cultivator/producer allowed to purchase cannabis products in your jurisdiction and that you comply with all relevant laws and regulations.</p>
<p><strong>1.2 Licensed Sales: </strong>Both the seller (Breeder/Nursery) and the buyer (Cultivator/Producer) must hold the necessary licenses to sell and purchase cannabis products. You are responsible for providing valid proof of your license upon request.</p>
<p><strong>1.3 Prohibited Jurisdictions:</strong> This website is not intended for use in jurisdictions where the sale or possession of cannabis is illegal. It is your responsibility to ensure compliance with your local laws.</p>
<p><strong>2. Eligibility</strong></p>
<p><strong>2.1 Age Requirement:</strong> You must be at least 21 years old to use this website and purchase products.</p>
<p><strong>2.2 Proof of Identity: </strong>We may require you to provide proof of identity, age, and license before redirecting a transaction.</p>
<p><strong>2.3 Account Registration:</strong> To purchase products, you must create an account and provide accurate, complete, and current information. You are responsible for maintaining the confidentiality of your account details and for all activities under your account.</p>
<p><strong>3. Products</strong></p>
<p><strong>3.1 Product Information:</strong> We strive to provide accurate descriptions of all products offered. However, we do not warrant that the product descriptions, prices, or other content on the website are accurate, complete, reliable, current, or error-free.</p>
<p><strong>3.2 Pricing:</strong> Prices are subject to change without notice. The price you pay for a product will be the price listed at the time of your order.</p>
<p><strong>3.3 Availability:</strong> All products are subject to availability. We reserve the right to limit the quantities of any products that we offer and to discontinue any product at any time.</p>
<p>4. Orders and Payment</p>
<p><strong>4.1 Order Acceptance:</strong> Your order constitutes an offer to purchase products. We reserve the right to accept or reject any order for any reason.</p>
<p><strong>4.2 Payment: </strong>All transactions are redirected off-site/app to be conducted entirely between the two licensed parties. Payments between two licensed parties should be submitted into each state’s compliance system. </p>
<p><strong>4.3 Taxes:</strong> You are responsible for paying all applicable taxes related to your purchase.</p>
<p><strong>4.4 Order Cancellation:</strong> We reserve the right to cancel any order for any reason, including but not limited to suspicion of fraud, unauthorized use of a payment method, or violations of these terms.</p>
<p><strong>5. Shipping and Delivery</strong></p>
<p><strong>5.1 Shipping:</strong> Shipping will be arranged off site/app between the two licensed parties.</p>
<p><strong>5.2 Delivery:</strong> Delivery/transit will be arranged off site/app between the two licensed parties.</p>
<p><strong>6. Returns and Refunds</strong></p>
<p><strong>6.1 Returns: </strong>Due to the nature of products offered, returns are not accepted unless the products are defective or incorrect.</p>
<p><strong>6.2 Refunds:</strong> Refunds will only be issued for defective or incorrect products. You must contact us within two business days of receiving the product to request a refund.</p>
<p><strong>7. Privacy Policy</strong></p>
<p><strong>7.1 Data Collection:</strong> We collect and use your personal information in accordance with our Privacy Policy, which is incorporated into these terms by reference.</p>
<p><strong>7.2 Security: </strong>We use reasonable security measures to protect your personal information. However, we cannot guarantee the absolute security of your data.</p>
<p><strong>8. Disclaimers and Limitations of Liability</strong></p>
<p><strong>8.1 No Warranties:</strong> The website and products are provided on an "as-is" and "as-available" basis. We make no warranties, express or implied, regarding the website or the products.</p>
<p><strong>8.2 Limitation of Liability:</strong> To the fullest extent permitted by law, we are not liable for any damages arising from your use of the website or purchase of products, including but not limited to direct, indirect, incidental, punitive, and consequential damages.</p>
<p><strong>9. Indemnification</strong></p>
<p>You agree to indemnify, defend, and hold harmless CannaGen, its affiliates, officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses arising from your use of the website, violation of these terms, or violation of any applicable law.</p>
<p><strong>10. Governing Law and Dispute Resolution</strong></p>
<p><strong>10.1 Governing Law:</strong> These terms are governed by and construed in accordance with the laws of [State/Country], without regard to its conflict of law principles.</p>
<p><strong>10.2 Dispute Resolution: </strong>Any disputes arising from these terms or your use of the website will be resolved through [arbitration/mediation] in Orange County, CA..</p>
<p><strong>11. Changes to Terms and Conditions</strong></p>
<p>We reserve the right to update or modify these terms at any time without prior notice. Your continued use of the website following any changes constitutes your acceptance of the revised terms.</p>
<p><strong>12. Contact Information</strong></p>
<p>If you have any questions about these terms, please contact us at <a href="mailto:admin@canna-gen.com">admin@canna-gen.com</a>.</p>
<p><br/></p>
              </Typography>
              <span>
              <Checkbox
                  name="agree_to_policy"
                  checked={this.state.signUpFormData.agree_to_policy}
                  onChange={this.handleChangeCheckbox}
                />
                <Typography component="span">
                  Agree to Cannagen</Typography></span>
                  
            </Paper>
               
              
            </Modal>
           
            <Typography style={{ fontFamily: 'Inter,sans-serif', fontSize: 16, fontWeight: "700" }}>
                Please share business information
              </Typography></div>
              {/* Name of Business Field */}
              <Box>
                <label style={formStyle.formlable}>Name of business</label>
                <TextField
                  style={formStyle.inputStyle}
                  placeholder="Name of business"
                  variant="outlined"
                  name="name_of_business"
                  value={this.state.signUpFormData.name_of_business}
                  onChange={this.handleChange}
                  error={!!this.state.businessNameError}
                  helperText={this.state.businessNameError}
                  size={'small'}
                />
              </Box>
              {/* License Number Field */}
              <Box>
                <label style={formStyle.formlable}>License number</label>
                <TextField
                  style={formStyle.inputStyle}
                  placeholder="License number"
                  variant="outlined"
                  name="license_number"
                  value={this.state.signUpFormData.license_number || ''}
                  onChange={this.handleChange}
                  error={!!this.state.licenseNumberError}
                  helperText={this.state.licenseNumberError}
                  size={'small'}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {/* County Field */}
                <Box sx={{ width: '50%', marginRight: '10px' }}>
                  <label style={formStyle.formlable}>County</label>
                  <TextField
                    style={{ ...formStyle.inputStyle }}
                    placeholder="County"
                    variant="outlined"
                    name="county"
                    value={this.state.signUpFormData.county}
                    onChange={this.handleChange}
                    error={!!this.state.countyError}
                    helperText={this.state.countyError}
                    size={'small'}
                  />
                </Box>
                {/* state Field */}
                <Box sx={{ width: '50%' }}>
                  <label style={formStyle.formlable}>State</label>
                  <Select
                    style={formStyle.inputStyle}
                    variant="outlined"
                    name="state"
                    value={this.state.signUpFormData.state}
                    onChange={(event) => this.handleChange(event as React.ChangeEvent<HTMLInputElement>)}
                    error={!!this.state.stateError}
                    size="small"
                  >
                    {this.state.usStates && this.state.usStates.map((state) => (
                      <MenuItem key={state.name} value={state.name}>
                        ({state.abbreviation}) {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {this.state.stateError && (
                    <FormHelperText error>{this.state.stateError}</FormHelperText>
                  )}
                </Box>
              </Box>
              {/* Zipcode Field */}
              <Box>
                <label style={formStyle.formlable}>Zip code</label>
                <TextField
                  style={formStyle.inputStyle}
                  placeholder="zip code"
                  variant="outlined"
                  name="zip_code"
                  value={this.state.signUpFormData.zip_code}
                  onChange={this.handleChange}
                  error={!!this.state.zipCodeError}
                  helperText={this.state.zipCodeError}
                  size={'small'}
                />
              </Box>
              {/* address Field */}
              <Box>
                <label style={formStyle.formlable}>Address</label>
                <TextField
                  style={formStyle.inputStyle}
                  placeholder="address"
                  variant="outlined"
                  name="address"
                  value={this.state.signUpFormData.address}
                  onChange={this.handleChange}
                  error={!!this.state.addressError}
                  helperText={this.state.addressError}
                  size={'small'}
                />
              </Box>
              {/* Privacy Policy Checkbox */}
              <Box>
                <Checkbox
                  name="agree_to_policy"
                  checked={this.state.signUpFormData.agree_to_policy}
                  onChange={this.handleChangeCheckbox}
                />
                <Typography component="span">
                  Agree to Cannagen
                </Typography> <span  style={{ color: "#007A2F", textDecoration: 'none' }} onClick={this.handleClickShowPassword}>Privacy Policy</span>
                {!!this.state.policyError && (
                  <FormHelperText style={{ color: 'red' }}>{this.state.policyError}</FormHelperText>
                )}
              </Box>
            </>}
          {/* Submit Button */}
          <Box sx={{mb:2}}>
            {this.state.steps <= 1 ? <Button variant="contained" type="button" sx={{
              ...formStyle.btnstyle, '& .MuiButtonBase-root': {
                backgroundColor: '#067B3E',
              },
            }} style={{ backgroundColor: '#067B3E' }} onClick={() => {
              this.goToNextStep()
            }} data-testid = "ctnbtn" >
              Continue
            </Button> :
              <Button variant="contained" type="submit" sx={{
                ...formStyle.btnstyle, '& .MuiButtonBase-root': {
                  backgroundColor: '#067B3E',
                },
              }} style={{ backgroundColor: '#067B3E' }} >
                SignUp
              </Button>}
          </Box>

          <Typography component="span" sx={{mt:2}}>Already have an account? </Typography> <a  style={{ color: "#007A2F", textDecoration: 'none' }} href={'/EmailAccountLoginBlock'} >Signin now</a>
               
        </form>
      )}
    </Formik>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Grid container component="main" sx={{ height: '100vh' }}>
        {/* <CssBaseline /> */}
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 1,
              mx: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'right',
            }}
          >
            <Box sx={{ mt: 1 }}>
              <Grid container sx={{ px: '43px', display: 'flex', flexDirection: 'column' }}>
                     {this.SignupForm()}
              </Grid>
              {/* <Copyright sx={{ mt: 5 }} /> */}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:this.state.steps ===0 ? 'url("https://media.licdn.com/dms/image/v2/D4D12AQFv8WNqRGfohQ/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1678531033963?e=2147483647&v=beta&t=GYZx6pqv4dojvpo9nEzMj0vTp3arpgYKKp0YWdYmhVI")'
              :'url(https://natrue.org/uploads/2020/08/Canva-null-2.jpg)',
              backgroundSize: 'cover',
            backgroundPosition: 'right',
          }}
        />
      </Grid>
      // Customizable Area End
    );
  }

}


// Customizable Area End
