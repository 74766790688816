// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Grid,
  Typography, Box, Paper
  // Customizable Area End
} from "@mui/material";



// Customizable Area Start


import EmailAccountRegistrationController, { Props } from "./EmailAccountRegistrationController";
// Customizable Area End

export default class EmialAccountRegistrationConfirmation extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start

      <Grid container component="main" sx={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }} data-testid='confirm'>
        <Grid item xs={12} sm={8} md={6}>
          <Paper elevation={3} sx={{ padding: 4, textAlign: 'center', borderRadius: 2 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
              We are verifying your license
            </Typography>
            
            <Box component="div"  sx={{ maxWidth: '100%', height: 'auto', marginBottom: 2 }} >
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="222" viewBox="0 0 100 222" fill="none">
<path d="M37.7889 215.889L32.4182 214.125L35.0702 201.22L40.4409 202.983L37.7889 215.889Z" fill="#FFB573"/>
<path d="M84.5022 215.956H78.8442L77.4414 202.87H83.0927L84.5022 215.956Z" fill="#FFB573"/>
<path d="M78.3833 215.302H85.2704C85.3835 215.302 85.4932 215.34 85.5818 215.41C85.6703 215.481 85.7324 215.579 85.7581 215.689L86.8736 220.713C86.8997 220.835 86.8979 220.962 86.8682 221.084C86.8385 221.206 86.7818 221.319 86.7022 221.416C86.6226 221.513 86.5223 221.591 86.4086 221.644C86.2948 221.696 86.1707 221.723 86.0453 221.721C83.8275 221.681 82.211 221.554 79.4188 221.554C77.6953 221.554 72.5183 221.728 70.1469 221.728C67.7755 221.728 67.4749 219.383 68.4302 219.169C72.7855 218.221 76.0721 216.905 77.4615 215.649C77.717 215.426 78.0443 215.303 78.3833 215.302Z" fill="#263238"/>
<path d="M32.5117 213.277L38.4636 214.914C38.5711 214.945 38.6649 215.012 38.7303 215.102C38.7957 215.193 38.8288 215.303 38.8243 215.415L38.5638 220.559C38.5586 220.683 38.5252 220.804 38.4661 220.914C38.4071 221.023 38.3239 221.118 38.2229 221.191C38.1219 221.264 38.0057 221.312 37.8831 221.334C37.7605 221.355 37.6346 221.348 37.5151 221.313C35.3775 220.686 32.3113 219.704 29.6126 218.955C26.4597 218.087 25.8718 217.786 22.1711 216.771C19.9333 216.15 19.9333 213.718 20.9286 213.772C25.451 214.012 26.7736 214.593 31.0822 213.338C31.5438 213.184 32.039 213.164 32.5117 213.277Z" fill="#263238"/>
<path d="M60.3541 44.2004C59.9934 44.668 59.7195 45.0153 59.3989 45.4094C59.0782 45.8036 58.7709 46.1509 58.4503 46.5183C57.7823 47.2464 57.1143 47.9545 56.4463 48.6492C55.0984 50.0653 53.6711 51.4035 52.1711 52.6572C49.0708 55.3395 45.5201 57.4518 41.6835 58.8964C40.6256 59.2693 39.5371 59.5487 38.4303 59.7314C38.1498 59.7714 37.8625 59.8249 37.5753 59.8583L36.7002 59.9384C36.3309 59.9608 35.9606 59.9608 35.5913 59.9384C35.2265 59.9175 34.8631 59.8773 34.5025 59.8182C33.3267 59.6056 32.1822 59.2465 31.0957 58.7494C30.1518 58.3372 29.2368 57.8618 28.3569 57.3266C26.697 56.3265 25.1099 55.2104 23.6074 53.9866C22.1225 52.8087 20.7146 51.5369 19.3923 50.179L21.9508 46.7989C25.0904 48.3553 28.3034 49.9986 31.3762 51.181C32.1444 51.4682 32.8993 51.7287 33.6074 51.9291C34.2048 52.1074 34.8216 52.2128 35.4444 52.2431C35.5312 52.2475 35.6181 52.2475 35.7049 52.2431C35.7517 52.2431 35.745 52.2431 35.952 52.1897L36.4196 52.1095L36.9006 51.9959C37.2146 51.9291 37.5686 51.7955 37.8692 51.7087C38.1698 51.6219 38.5372 51.4749 38.8579 51.3212C40.2547 50.7249 41.5828 49.979 42.8191 49.0968C44.1595 48.1798 45.4382 47.1757 46.6467 46.0908C47.8959 45.0086 49.0916 43.8263 50.2673 42.6239C50.8551 42.0227 51.4296 41.4014 51.984 40.7735L52.8124 39.8317L53.5739 38.9365L60.3541 44.2004Z" fill="#FFB573"/>
<path d="M63.4602 41.4215C63.8743 46.8056 51.7435 57.8009 51.7435 57.8009L41.0154 48.5023C41.0154 48.5023 45.5311 43.7194 50.6079 38.9032C54.0948 35.5966 63.0059 35.5899 63.4602 41.4215Z" fill="white"/>
<path opacity="0.4" d="M63.4602 41.4215C63.8743 46.8056 51.7435 57.8009 51.7435 57.8009L41.0154 48.5023C41.0154 48.5023 45.5311 43.7194 50.6079 38.9032C54.0948 35.5966 63.0059 35.5899 63.4602 41.4215Z" fill="#067B3E"/>
<path opacity="0.2" d="M57.976 44.2C52.9927 44.6542 49.8064 48.6622 48.4102 53.091C48.2566 53.5854 48.123 54.093 48.0161 54.594L51.7302 57.8138C51.7302 57.8138 57.2546 52.8105 60.7148 47.9007C60.9018 45.5694 60.2405 43.9862 57.976 44.2Z" fill="black"/>
<path d="M23.2401 49.2365L21.403 44.5605L15.1372 46.4844C16.5006 47.9323 18.0043 49.2413 19.6262 50.3922L23.2401 49.2365Z" fill="#FFB573"/>
<path d="M18.2633 40.9336L13.093 43.0044L15.1571 46.498L21.423 44.5742L18.2633 40.9336Z" fill="#FFB573"/>
<path opacity="0.2" d="M35.0704 201.227L33.4739 208.975L38.9247 210.365L40.4411 202.99L35.0704 201.227Z" fill="black"/>
<path opacity="0.2" d="M83.0927 202.87H77.4414L78.1695 209.617H83.8208L83.0927 202.87Z" fill="black"/>
<path d="M86.4328 38.8091C84.7738 44.9101 83.5726 51.1264 82.8389 57.4062C82.3045 61.7816 81.9839 65.9633 81.7835 69.7575C81.3159 78.6419 81.5096 85.4154 81.2424 87.7067L51.0822 85.6158C46.9807 53.4583 54.823 36.8919 54.823 36.8919C57.2075 36.4489 59.6168 36.1522 62.0374 36.0035C62.5496 35.9679 63.0706 35.9478 63.6005 35.9434C67.8874 36.001 72.1674 36.3043 76.4195 36.8518C77.2277 36.952 78.0427 37.0656 78.8376 37.1925C82.879 37.8271 86.4328 38.8091 86.4328 38.8091Z" fill="white"/>
<path opacity="0.4" d="M86.4328 38.8091C84.7738 44.9101 83.5726 51.1264 82.8389 57.4062C82.3045 61.7816 81.9839 65.9633 81.7835 69.7575C81.3159 78.6419 81.5096 85.4154 81.2424 87.7067L51.0822 85.6158C46.9807 53.4583 54.823 36.8919 54.823 36.8919C57.2075 36.4489 59.6168 36.1522 62.0374 36.0035C62.5496 35.9679 63.0706 35.9478 63.6005 35.9434C67.8874 36.001 72.1674 36.3043 76.4195 36.8518C77.2277 36.952 78.0427 37.0656 78.8376 37.1925C82.879 37.8271 86.4328 38.8091 86.4328 38.8091Z" fill="#067B3E"/>
<path opacity="0.2" d="M78.811 46.9453C78.761 51.5708 80.0585 56.1108 82.5451 60.0114C82.6319 59.1564 82.7321 58.2813 82.839 57.4062C83.2398 54.113 83.7608 50.7262 84.4489 47.2659L78.811 46.9453Z" fill="black"/>
<path d="M76.159 22.3027C75.0568 26.3709 73.7274 33.8725 76.4195 36.8251C75.0566 38.0613 73.4444 38.9908 71.6916 39.5508C69.9389 40.1109 68.0864 40.2884 66.2592 40.0715C62.7188 39.7442 62.7121 37.2526 63.5872 35.9299C68.2632 35.1551 68.3835 31.7349 67.8023 28.4751L76.159 22.3027Z" fill="#FFB573"/>
<path opacity="0.2" d="M72.7588 24.8281L67.8223 28.4754C67.9685 29.2351 68.0467 30.0064 68.0561 30.78C69.8329 30.6464 72.378 28.8896 72.6987 27.0592C72.8525 26.3252 72.8729 25.5694 72.7588 24.8281Z" fill="black"/>
<path d="M67.1943 16.2175C65.8583 18.108 62.3513 14.6277 62.4849 11.5415C67.5617 10.8735 65.1569 7.15276 69.7661 8.108C73.6004 8.86284 68.6639 14.14 67.1943 16.2175Z" fill="#263238"/>
<path d="M79.8463 17.4401C78.1496 22.7841 77.4282 26.0039 73.9813 28.1281C68.7976 31.2811 62.7255 27.1061 62.7923 21.3613C62.8524 16.191 65.6246 8.31527 71.4763 7.49363C72.7644 7.30637 74.079 7.45095 75.2957 7.91369C76.5123 8.37644 77.5908 9.14202 78.4289 10.138C79.267 11.1339 79.837 12.3273 80.0851 13.6051C80.3331 14.883 80.2509 16.203 79.8463 17.4401Z" fill="#FFB573"/>
<path d="M76.64 22.3031C76.3798 20.9839 76.3056 19.6348 76.4196 18.2951C76.6114 17.2474 76.9416 16.2298 77.4015 15.269C77.4015 15.269 74.2018 13.3786 74.1283 11.1007C74.1283 11.1007 68.4102 12.7841 65.3575 10.2123C62.3047 7.64047 65.0302 4.66787 63.467 3.53227C67.2078 3.17155 68.3434 5.44275 70.7082 4.94843C73.0729 4.45411 70.982 2.79079 71.296 0.94043C78.4236 7.07935 81.5231 4.20027 84.9833 7.71395C87.1944 9.95843 84.8698 14.1668 84.8698 14.1668C84.8698 14.1668 88.3968 20.1521 76.64 22.3031Z" fill="#263238"/>
<path d="M83.7341 14.0396C83.7341 14.0396 87.2345 14.7611 87.328 11.0938C89.1316 14.8078 86.8737 15.389 83.7341 14.0396Z" fill="#263238"/>
<path d="M67.9425 22.5367C68.5784 22.7127 69.2473 22.7329 69.8926 22.5957C70.5379 22.4585 71.1407 22.1679 71.6499 21.7485C71.6773 21.721 71.6926 21.6837 71.6926 21.6449C71.6926 21.6062 71.6773 21.5689 71.6499 21.5414C71.6227 21.5161 71.5869 21.502 71.5498 21.502C71.5126 21.502 71.4767 21.5161 71.4495 21.5414C70.9731 21.9234 70.4133 22.1878 69.8156 22.3131C69.2179 22.4385 68.5991 22.4212 68.0093 22.2629C67.9715 22.256 67.9324 22.2631 67.8993 22.2827C67.8662 22.3023 67.8412 22.3332 67.829 22.3697C67.8245 22.4066 67.8336 22.4438 67.8545 22.4745C67.8753 22.5052 67.9066 22.5273 67.9425 22.5367Z" fill="#263238"/>
<path d="M80.7949 23.3452C80.2903 23.8278 79.6954 24.2061 79.0443 24.4583C78.3932 24.7105 77.6987 24.8317 77.0007 24.8148C75.1303 24.7614 74.7495 22.9578 75.7314 21.4748C76.6199 20.1388 78.6573 18.5824 80.3139 19.3572C81.9706 20.1321 82.024 22.1562 80.7949 23.3452Z" fill="#FFB573"/>
<path d="M31.9775 206.19L41.2026 208.729C41.2026 208.729 52.759 172.303 55.598 152.296C56.3529 146.985 57.5152 139.53 59.2854 131.588C61.5299 121.568 64.3355 110.74 66.6334 102.256C69.0649 93.3183 70.9086 86.999 70.9086 86.999L51.0823 85.6162C51.0823 85.6162 42.9661 130.032 41.0623 147.179C39.0984 165.001 31.9775 206.19 31.9775 206.19Z" fill="#067B3E"/>
<path opacity="0.3" d="M31.9775 206.19L41.2026 208.729C41.2026 208.729 52.759 172.303 55.598 152.296C56.3529 146.985 57.5152 139.53 59.2854 131.588C61.5299 121.568 64.3355 110.74 66.6334 102.256C69.0649 93.3183 70.9086 86.999 70.9086 86.999L51.0823 85.6162C51.0823 85.6162 42.9661 130.032 41.0623 147.179C39.0984 165.001 31.9775 206.19 31.9775 206.19Z" fill="black"/>
<path opacity="0.2" d="M59.312 131.581C61.5565 121.561 64.3621 110.733 66.66 102.249L64.6026 97.8135C59.6594 103.652 58.3367 121.748 59.312 131.581Z" fill="black"/>
<path d="M61.7769 86.3574C61.7769 86.3574 63.9211 134.747 66.3059 151.153C68.9178 169.103 75.5577 208.328 75.5577 208.328H85.905C85.905 208.328 82.4849 167.052 80.9685 149.43C79.2451 129.39 81.2424 87.7268 81.2424 87.7268L61.7769 86.3574Z" fill="#067B3E"/>
<path opacity="0.3" d="M61.7769 86.3574C61.7769 86.3574 63.9211 134.747 66.3059 151.153C68.9178 169.103 75.5577 208.328 75.5577 208.328H85.905C85.905 208.328 82.4849 167.052 80.9685 149.43C79.2451 129.39 81.2424 87.7268 81.2424 87.7268L61.7769 86.3574Z" fill="black"/>
<path d="M74.3153 208.535H86.5063V204.894L72.8792 204.66L74.3153 208.535Z" fill="#067B3E"/>
<path d="M31.3362 206.243L41.757 209.116L43.2533 205.529L31.3829 202.235L31.3362 206.243Z" fill="#067B3E"/>
<path d="M71.229 16.8859C71.1221 17.3267 70.7948 17.6273 70.5075 17.5539C70.2203 17.4804 70.0734 17.0662 70.1869 16.6253C70.3005 16.1845 70.6211 15.8839 70.9084 15.9573C71.1956 16.0308 71.3359 16.445 71.229 16.8859Z" fill="#263238"/>
<path d="M66.2326 15.63C66.119 16.0709 65.7984 16.3715 65.5111 16.298C65.2239 16.2246 65.0769 15.8104 65.1838 15.3628C65.2907 14.9153 65.6247 14.6214 65.9119 14.6948C66.1992 14.7683 66.3461 15.1892 66.2326 15.63Z" fill="#263238"/>
<path d="M67.769 16.251C66.9009 17.4688 65.8691 18.5612 64.7029 19.4975C65.3041 20.426 66.8004 20.3325 66.8004 20.3325L67.769 16.251Z" fill="#ED893E"/>
<path d="M73.1863 15.6629C73.1367 15.659 73.0891 15.6413 73.049 15.6118C73.0088 15.5823 72.9777 15.5421 72.9592 15.4959C72.8362 15.1839 72.6415 14.9053 72.3908 14.6825C72.1402 14.4596 71.8406 14.2989 71.5163 14.2133C71.4458 14.2003 71.3832 14.1601 71.342 14.1014C71.3008 14.0427 71.2842 13.9701 71.2959 13.8994C71.3023 13.8646 71.3155 13.8315 71.3348 13.8019C71.3541 13.7723 71.3791 13.7469 71.4083 13.727C71.4375 13.7071 71.4703 13.6932 71.5049 13.6861C71.5395 13.679 71.5752 13.6788 71.6099 13.6856C72.0225 13.787 72.405 13.9853 72.7257 14.2641C73.0463 14.543 73.2958 14.8942 73.4535 15.2888C73.4807 15.3545 73.4812 15.4281 73.455 15.4942C73.4288 15.5603 73.378 15.6136 73.3133 15.6429C73.2722 15.656 73.2294 15.6627 73.1863 15.6629Z" fill="#263238"/>
<path d="M64.5225 13.3723C64.4658 13.3658 64.4123 13.3425 64.3689 13.3055C64.3199 13.2555 64.2925 13.1884 64.2925 13.1184C64.2925 13.0485 64.3199 12.9813 64.3689 12.9314C64.6355 12.5991 64.9777 12.3354 65.367 12.1622C65.7563 11.9891 66.1813 11.9116 66.6067 11.9361C66.677 11.9461 66.7407 11.983 66.7844 12.0391C66.828 12.0952 66.8481 12.166 66.8405 12.2367C66.829 12.3063 66.7916 12.369 66.7359 12.4124C66.6802 12.4557 66.6102 12.4765 66.5399 12.4705C66.2044 12.4547 65.87 12.5192 65.5644 12.6586C65.2588 12.798 64.991 13.0083 64.783 13.2721C64.7509 13.3086 64.7105 13.3368 64.6651 13.3542C64.6197 13.3717 64.5708 13.3779 64.5225 13.3723Z" fill="#263238"/>
<path d="M76.5129 216.29C76.1281 216.319 75.7446 216.218 75.4241 216.003C75.3235 215.916 75.2458 215.807 75.198 215.683C75.1502 215.56 75.1338 215.426 75.1502 215.295C75.1497 215.216 75.1705 215.14 75.2106 215.072C75.2506 215.005 75.3083 214.95 75.3773 214.914C76.0053 214.593 77.8289 215.715 78.0493 215.842C78.0725 215.857 78.0907 215.878 78.1014 215.903C78.1122 215.928 78.115 215.956 78.1095 215.983C78.1059 216.009 78.0943 216.033 78.0764 216.052C78.0584 216.071 78.035 216.084 78.0093 216.09C77.5197 216.213 77.0177 216.28 76.5129 216.29ZM75.6713 215.121C75.6117 215.12 75.5527 215.132 75.4976 215.154C75.4642 215.154 75.4241 215.208 75.4174 215.315C75.4053 215.403 75.4154 215.494 75.4469 215.578C75.4784 215.662 75.5302 215.737 75.5978 215.796C75.9051 216.056 76.6466 216.096 77.6018 215.902C77.0163 215.517 76.3601 215.251 75.6713 215.121Z" fill="#067B3E"/>
<path d="M77.9625 216.09C77.9408 216.097 77.9174 216.097 77.8957 216.09C77.3145 215.769 76.1789 214.533 76.2991 213.905C76.319 213.806 76.3724 213.717 76.4502 213.652C76.528 213.588 76.6256 213.552 76.7267 213.551C76.8282 213.538 76.9315 213.546 77.0294 213.576C77.1274 213.606 77.2178 213.657 77.2945 213.725C77.9625 214.259 78.0894 215.889 78.096 215.963C78.0969 215.986 78.0918 216.009 78.0813 216.03C78.0708 216.051 78.0552 216.069 78.0359 216.083C78.0123 216.091 77.9871 216.093 77.9625 216.09ZM76.8202 213.798H76.76C76.5864 213.798 76.573 213.898 76.5663 213.932C76.4928 214.306 77.2343 215.268 77.7955 215.682C77.7547 215.038 77.522 214.422 77.1275 213.912C77.0421 213.838 76.9329 213.798 76.8202 213.798Z" fill="#067B3E"/>
<path d="M31.7704 213.873C30.8418 213.799 29.2186 213.585 28.771 212.891C28.7086 212.792 28.6701 212.681 28.6585 212.565C28.6469 212.449 28.6626 212.331 28.7042 212.223C28.7267 212.153 28.7669 212.091 28.8208 212.042C28.8746 211.993 28.9404 211.958 29.0115 211.942C29.7663 211.762 31.6835 213.465 31.8705 213.666C31.8895 213.684 31.9027 213.708 31.9087 213.734C31.9146 213.76 31.9129 213.787 31.9039 213.812C31.8906 213.835 31.8706 213.853 31.8467 213.864C31.8228 213.875 31.7961 213.878 31.7704 213.873ZM29.0984 212.176H29.0583C29.0298 212.182 29.0036 212.196 28.9824 212.216C28.9612 212.236 28.9459 212.261 28.938 212.289C28.9077 212.363 28.8956 212.442 28.9026 212.521C28.9095 212.601 28.9354 212.677 28.9781 212.744C29.2319 213.138 30.1137 213.412 31.3762 213.565C30.588 212.924 29.5325 212.136 29.0917 212.183L29.0984 212.176Z" fill="#067B3E"/>
<path d="M31.6702 213.873C31.1626 213.098 30.3343 211.675 30.6215 210.9C30.6605 210.784 30.7272 210.68 30.8157 210.596C30.9042 210.513 31.0118 210.452 31.1292 210.419C31.1988 210.395 31.2733 210.389 31.3457 210.402C31.4182 210.415 31.4862 210.446 31.5433 210.492C32.1445 210.987 31.9441 213.525 31.9174 213.832C31.9142 213.859 31.9029 213.885 31.885 213.905C31.8671 213.925 31.8434 213.939 31.8172 213.946C31.788 213.953 31.7572 213.95 31.7302 213.936C31.7033 213.923 31.6821 213.9 31.6702 213.873ZM31.383 210.706C31.3589 210.691 31.3311 210.684 31.3029 210.684C31.2746 210.684 31.2468 210.691 31.2227 210.706C31.1448 210.727 31.0732 210.767 31.0139 210.821C30.9546 210.876 30.9093 210.944 30.882 211.02C30.7284 211.461 31.029 212.356 31.6903 213.418C31.7237 212.336 31.6903 211 31.383 210.679V210.706Z" fill="#067B3E"/>
<path d="M87.2678 42.0625C88.5704 43.8995 89.6793 45.6764 90.8149 47.5267C91.9505 49.3771 92.9859 51.2475 94.0012 53.1647C96.1032 57.0743 97.8127 61.1825 99.1048 65.4291C99.2584 65.9903 99.4054 66.5514 99.539 67.1325C99.6058 67.4198 99.6659 67.7137 99.726 68.0143L99.8128 68.4552L99.8529 68.6823L99.9063 69.043C100.076 70.5625 99.9378 72.1007 99.4989 73.5654C98.8375 75.8284 97.902 78.0022 96.7133 80.0383C95.6398 81.9453 94.4554 83.7877 93.1662 85.556C91.908 87.3144 90.5544 89.0025 89.1115 90.6127L85.6379 88.1745C87.4482 84.5473 89.2918 80.8265 90.708 77.1793C91.062 76.2708 91.376 75.3623 91.6499 74.4739C91.9219 73.6454 92.1274 72.7966 92.2644 71.9355C92.3785 71.3801 92.3785 70.8074 92.2644 70.2521L92.2177 70.0985L92.1308 69.7845C92.0774 69.5707 92.0173 69.357 91.9438 69.1165C91.8169 68.689 91.6699 68.2481 91.5163 67.8139C90.1271 64.1561 88.4426 60.6174 86.4796 57.2328C85.5043 55.4759 84.4755 53.7525 83.4201 52.0157C82.3647 50.2789 81.2558 48.5555 80.207 46.9456L87.2678 42.0625Z" fill="#FFB573"/>
<path d="M86.4327 38.8095C91.7299 39.8783 96.3725 52.8375 96.3725 52.8375L85.3171 61.8422C85.3171 61.8422 81.6966 55.2557 78.7974 48.903C75.6378 41.9825 80.0199 37.5203 86.4327 38.8095Z" fill="white"/>
<path opacity="0.4" d="M86.4327 38.8095C91.7299 39.8783 96.3725 52.8375 96.3725 52.8375L85.3171 61.8422C85.3171 61.8422 81.6966 55.2557 78.7974 48.903C75.6378 41.9825 80.0199 37.5203 86.4327 38.8095Z" fill="#067B3E"/>
<path d="M12.4853 57.1651L9.8147 66.7949L10.452 66.9717L13.1226 57.3418L12.4853 57.1651Z" fill="#067B3E"/>
<path d="M8.832 70.343L7.677 74.5078L8.31427 74.6845L9.46927 70.5198L8.832 70.343Z" fill="#067B3E"/>
<path d="M83.1905 52.8501L27.8737 37.2922C23.6697 36.1099 19.3032 38.5594 18.1208 42.7634L2.56299 98.0802C1.38062 102.284 3.83013 106.651 8.03412 107.833L63.3509 123.391C67.5549 124.573 71.9214 122.124 73.1038 117.92L88.6616 62.603C89.844 58.399 87.3945 54.0325 83.1905 52.8501Z" fill="#067B3E"/>
<path d="M50.9954 67.7208C50.6972 67.7231 50.4002 67.6826 50.1136 67.6005C49.275 67.3588 48.5643 66.7985 48.1336 66.0395C47.7029 65.2804 47.5864 64.3829 47.809 63.5391L50.481 53.9132C50.7266 53.0815 51.289 52.3796 52.0471 51.9587C52.8053 51.5378 53.6984 51.4315 54.5341 51.6627C55.3699 51.894 56.0813 52.4443 56.5152 53.1951C56.9491 53.9459 57.0706 54.8371 56.8537 55.6767L54.1817 65.3026C53.9828 65.9956 53.5649 66.6056 52.9906 67.0414C52.4163 67.4773 51.7163 67.7156 50.9954 67.7208Z" fill="white"/>
<path d="M39.4858 107.98C39.305 107.982 39.1249 107.957 38.9514 107.907C38.4479 107.762 38.0212 107.425 37.7628 106.97C37.5043 106.514 37.4346 105.975 37.5686 105.469L40.2406 95.8495C40.2755 95.5634 40.3716 95.2882 40.5225 95.0427C40.6734 94.7971 40.8755 94.587 41.1149 94.4267C41.3544 94.2664 41.6256 94.1596 41.9101 94.1136C42.1946 94.0676 42.4857 94.0835 42.7634 94.1602C43.0412 94.237 43.2992 94.3727 43.5198 94.5582C43.7403 94.7437 43.9183 94.9746 44.0415 95.2351C44.1647 95.4956 44.2303 95.7796 44.2337 96.0678C44.2372 96.356 44.1785 96.6415 44.0616 96.9049L41.3896 106.524C41.2715 106.94 41.0224 107.306 40.6794 107.568C40.3363 107.831 39.9176 107.975 39.4858 107.98Z" fill="white"/>
<path d="M61.0021 73.2177C60.7827 73.2199 60.5688 73.149 60.3941 73.0162C60.2194 72.8834 60.0938 72.6963 60.0372 72.4843C59.9805 72.2723 59.996 72.0475 60.0812 71.8452C60.1664 71.643 60.3165 71.4749 60.5078 71.3674L69.1918 66.4442C69.4194 66.3204 69.6862 66.2903 69.9357 66.3601C70.1852 66.43 70.3976 66.5943 70.5278 66.8183C70.6551 67.0451 70.6872 67.3132 70.6171 67.5637C70.5469 67.8141 70.3803 68.0265 70.1537 68.1543L61.4697 73.0774C61.3274 73.1613 61.167 73.2094 61.0021 73.2177Z" fill="white"/>
<path d="M23.4605 95.8033C22.9477 95.8065 22.4485 95.6391 22.0413 95.3274C21.6341 95.0158 21.3421 94.5776 21.2113 94.0818C21.0804 93.586 21.1182 93.0608 21.3186 92.5888C21.519 92.1168 21.8706 91.7249 22.3182 91.4747L31.0022 86.5515C31.5276 86.3172 32.1213 86.2867 32.6679 86.4658C33.2145 86.645 33.6751 87.0209 33.9599 87.5207C34.2448 88.0204 34.3336 88.6083 34.2092 89.1699C34.0848 89.7315 33.756 90.2268 33.2867 90.5595L24.6027 95.4827C24.2556 95.6854 23.8624 95.7958 23.4605 95.8033Z" fill="white"/>
<path d="M72.171 88.8091C72.0515 88.8094 71.9324 88.7937 71.817 88.7623L62.1978 86.0903C61.9017 85.9628 61.6618 85.7325 61.5222 85.4419C61.3826 85.1513 61.3527 84.8201 61.4382 84.5093C61.5236 84.1984 61.7185 83.9289 61.9871 83.7506C62.2556 83.5722 62.5795 83.4969 62.8992 83.5386L72.5251 86.2106C72.8185 86.3105 73.068 86.5092 73.2309 86.7729C73.3939 87.0365 73.4601 87.3486 73.4183 87.6556C73.3764 87.9627 73.2291 88.2457 73.0016 88.4561C72.7741 88.6666 72.4804 88.7913 72.171 88.8091Z" fill="white"/>
<path d="M30.5879 78.6157C30.3487 78.6171 30.1105 78.5856 29.8799 78.5221L20.2607 75.8501C19.6731 75.5916 19.1983 75.1302 18.923 74.5504C18.6477 73.9705 18.5904 73.3109 18.7615 72.6922C18.9325 72.0736 19.3206 71.5372 19.8547 71.1811C20.3888 70.8251 21.0333 70.6732 21.6701 70.7533L31.2893 73.4253C31.8718 73.6276 32.3663 74.025 32.6891 74.5503C33.012 75.0755 33.1432 75.6963 33.0607 76.3072C32.9781 76.9182 32.6868 77.4818 32.2361 77.9026C31.7854 78.3233 31.2031 78.5752 30.5879 78.6157Z" fill="white"/>
<path d="M59.6593 105.162C59.3675 105.161 59.0811 105.084 58.8289 104.937C58.5767 104.791 58.3677 104.58 58.223 104.327L53.2999 95.6362C53.1884 95.4473 53.1157 95.2381 53.086 95.0209C53.0563 94.8036 53.0702 94.5826 53.1269 94.3707C53.1836 94.1589 53.282 93.9605 53.4163 93.7871C53.5506 93.6137 53.7181 93.4689 53.909 93.361C54.0999 93.2531 54.3104 93.1843 54.5282 93.1587C54.746 93.1331 54.9667 93.1512 55.1775 93.2119C55.3882 93.2726 55.5847 93.3747 55.7555 93.5123C55.9263 93.6498 56.068 93.82 56.1723 94.0129L61.0888 102.697C61.1969 102.885 61.2666 103.093 61.2938 103.309C61.3211 103.524 61.3054 103.743 61.2476 103.952C61.1898 104.161 61.0912 104.357 60.9573 104.528C60.8234 104.699 60.6569 104.842 60.4675 104.948C60.2215 105.089 59.9428 105.163 59.6593 105.162Z" fill="white"/>
<path d="M38.4035 68.9161C37.8765 68.9169 37.3587 68.7774 36.9033 68.5122C36.4478 68.2469 36.0711 67.8653 35.8117 67.4065L30.8952 58.7225C30.7027 58.3825 30.579 58.008 30.5312 57.6203C30.4833 57.2325 30.5124 56.8392 30.6166 56.4626C30.7208 56.0861 30.8981 55.7338 31.1385 55.4258C31.3789 55.1179 31.6775 54.8602 32.0175 54.6677C32.3574 54.4751 32.7319 54.3514 33.1197 54.3036C33.5074 54.2558 33.9007 54.2848 34.2773 54.389C34.6538 54.4933 35.0061 54.6706 35.3141 54.911C35.6221 55.1513 35.8797 55.45 36.0722 55.7899L40.9887 64.4739C41.1814 64.8133 41.3053 65.1873 41.3531 65.5746C41.401 65.9619 41.372 66.3548 41.2678 66.7309C41.1635 67.1069 40.9861 67.4587 40.7456 67.7661C40.5051 68.0734 40.2064 68.3303 39.8665 68.522C39.4221 68.7801 38.9174 68.9161 38.4035 68.9161Z" fill="white"/>
<path d="M87.6419 86.879L81.9104 86.6719L84.6826 93.3519C84.6826 93.3519 89.6058 92.4434 89.4187 89.7848L87.6419 86.879Z" fill="#FFB573"/>
<path d="M78.0693 89.424L79.4521 94.5209L84.6892 93.3385L81.9103 86.6719L78.0693 89.424Z" fill="#FFB573"/>
</svg>
                </Box>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              Don’t worry, you will get approved soon...
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 3 }}>
              It takes 2 business days to get verified
            </Typography>
            <> <Typography component="span" sx={{mt:2}}>Already have an account? </Typography> <a  style={{ color: "#007A2F", textDecoration: 'none' }} href={'/EmailAccountLoginBlock'} >Signin now</a>
            </>
           { <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Email verification link has been sent to your mail, please complete the verification process
            </Typography>}
          </Paper>
        </Grid>
      </Grid>

      // Customizable Area End
    );
  }

}


// Customizable Area End