Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "subscriptionbilling2";
exports.labelBodyText = "subscriptionbilling2 Body";
exports.StripeApiFeatch = 'bx_block_custom_user_subs/subscriptions'
exports.CreateCustomer = 'bx_block_stripe_integration/customers'
exports.UserInfoApi = 'account_block/accounts/logged_user'
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End